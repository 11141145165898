import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/opt/app-root/src/common/components/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExternalLink"] */ "/opt/app-root/src/common/components/external-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageTitle"] */ "/opt/app-root/src/common/components/page-title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EktepaktSearchCard"] */ "/opt/app-root/src/features/search/components/ektepakt-search-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MotorvognSearchCard"] */ "/opt/app-root/src/features/search/components/motorvogn-search-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PersonSearchCard"] */ "/opt/app-root/src/features/search/components/person-search-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VirksomhetSearchCard"] */ "/opt/app-root/src/features/search/components/virksomhet-search-card.tsx");
