'use client';
import {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {Card, CardContent, CardHeader, Collapse} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {colorPalette} from 'styles/colors';
import {useWindowEventListener} from 'common/hooks/use-event-listener';
import {signIn, useSession} from 'next-auth/react';
import {CssBreakpoints} from 'styles/responsive';
import Image from 'next/image';
import {Icon} from 'common/components/icon';
import {ExternalLinkIcon} from '@navikt/aksel-icons';
import {useTranslation} from 'react-i18next';

const useSearchCardStyles = createUseStyles((theme) => ({
  container: {
    '&.MuiPaper-root': {
      backgroundColor: theme.colors.accent,
      borderRadius: 0,
      boxShadow: theme.shadow.card,
      margin: '2.5em 0',
    }
  },
  headerButton: {
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    width: '100%',
    padding: '3px',

    '&:hover': {
      backgroundColor: theme.colors.hover,
      borderBottom: `3px solid ${theme.colors.secondary}`,
      paddingBottom: '0',
    },
    '&:focus, &:focus-visible': {
      backgroundColor: theme.colors.focus,
      border: `3px solid ${theme.colors.secondary}`,
      outline: 'none',
      padding: '0'
    }
  },
  headerButtonInner: {
    display: 'grid',
    gridTemplateColumns: '5fr 6fr',
    gridTemplateRows: 'auto auto',
    borderBottom: `1px solid ${colorPalette.grey20}`,
    margin: '1.5em',

    [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
      gridTemplateColumns: '5fr 8fr',
      borderBottom: 'none',
      margin: '1em',
    },

  },
  header: {
    '&.MuiCardHeader-root': {
      margin: '1.5em 1.5em',
      padding: 0,
      gap: '6em',

      display: 'contents',
      '& div.MuiCardHeader-content': {
        display: 'contents',

        '& .MuiCardHeader-title': {
          gridColumn: '2 / 3',
          gridRow: '1 / 2',
          alignSelf: 'end',

          [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
            gridColumn: '2 / 2',
            gridRow: '1 / 1',
          },

          [`@media (max-width: ${CssBreakpoints.XS}px)`]: {
            gridColumn: '1 / 3',
            gridRow: '1 / 1',
          }
        },
        '& .MuiCardHeader-subheader': {
          gridColumn: '2 / 2',
          gridRow: '2 / 3',

          [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
            gridColumn: '1 / 3',
            gridRow: '2 / 2',
            borderTop: `1px solid ${colorPalette.grey20}`,
            paddingTop: '0.5em'
          }
        }
      }
    }
  },
  illustration: {
    maxWidth: '370px',
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
    justifySelf: 'center',
    alignSelf: 'end',
    height: 'auto',
    width: '100%',

    [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
      maxWidth: '200px',
      gridColumn: '1 / 1',
      gridRow: '1 / 1',
    },

    [`@media (max-width: ${CssBreakpoints.XS}px)`]: {
      display: 'none'
    }
  },
  title: {
    color: theme.colors.text.heading,
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.5em',

    [`@media (max-width: ${CssBreakpoints.XS}px)`]: {
      marginTop: '0.5em',
    }
  },
  subheader: {
    color: theme.colors.text.heading,
    fontSize: '1.25rem',
    lineHeight: '1.5em',
    margin: 0,

    [`@media (max-width: ${CssBreakpoints.MD}px)`]: {
      fontSize: '1rem',
    },

    [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
      color: theme.colors.text.p,
      fontSize: '1rem',
    },

    [`@media (max-width: ${CssBreakpoints.XS}px)`]: {
      '& span': {
        display: 'none'
      }
    },
  },
  content: {
    '&.MuiCardContent-root': {
      margin: '1em 1.5em 0',
      padding: '0 0 0.5rem',

      [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
        margin: '1em 1em 0'
      }
    }
  },
  loginRequired: {
    fontStyle: 'italic',
    textDecoration: 'dotted underline',
    display: 'inline-block',
  }
}), {name: 'SearchCard'});

interface SearchCardProps {
  children: ReactNode;
  id: string;
  requiresAuth?: boolean;
  imageSrc: string;
  title: ReactNode;
  subheader: ReactNode;
}

export const SearchCard: FC<SearchCardProps> = ({
  id,
  requiresAuth,
  imageSrc,
  title,
  subheader,
  children
}) => {
  const styles = useSearchCardStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation(['search']);

  const [isOpen, updateOpenState] = useState<boolean>(false);
  const session = useSession();
  const sessionStatus = session.status;
  const sessionError = session?.data?.error;

  const [isAuthenticated, setIsAuthenticated] = useState(sessionStatus === 'authenticated' && !sessionError);

  useEffect(() => {
    if ((sessionStatus === 'unauthenticated' || sessionError) && requiresAuth && isOpen) {
      signIn('idporten', {redirect: false});
    }
  }, [isOpen]);

  useEffect(() => {
    if ((sessionStatus === 'unauthenticated' || sessionError) && requiresAuth && isOpen) {
      window.location.hash = '';
    }

    if (sessionStatus !== 'loading') {
      setIsAuthenticated(sessionStatus === 'authenticated' && !sessionError);
    }
  }, [sessionStatus]);

  useEffect(() => {
    setIsAuthenticated(sessionStatus === 'authenticated' && !sessionError);
  }, [sessionError]);


  useEffect(() => {
    if (window.location.hash.substring(1) === id) {
      updateOpenState(true);
      containerRef?.current?.scrollIntoView(true);
    }
  }, []);

  useWindowEventListener('popstate', () => {
    updateOpenState(window.location.hash.substring(1) === id);
  }, []);

  const open = () => {
    if (requiresAuth && !isAuthenticated) {
      const callbackUrl = new URL(window.location.href);
      callbackUrl.hash = id;
      signIn('idporten', {redirect: false, callbackUrl: callbackUrl.toString()});
    } else {
      window.location.hash = isOpen ? '' : `#${id}`;
    }
  };

  return (
    <Card className={styles.container} ref={containerRef}>
      <button id={`${id}-toggle`}
              data-transaction-name={`toggle-${id}-card`}
              onClick={open}
              className={styles.headerButton}
              aria-labelledby={`${id}-title ${id}-subtitle`}
              aria-expanded={isOpen}>
        <span className={styles.headerButtonInner}>
          <Image src={imageSrc} alt='' className={styles.illustration}/>
          <CardHeader className={styles.header}
                      title={<h2 id={`${id}-title`} className={styles.title}>{title}</h2>}
                      subheader={
                        <p id={`${id}-subtitle`} className={styles.subheader}>
                          {subheader}
                          {(requiresAuth && !isAuthenticated) && (
                            <>
                              {' '}
                              <span className={styles.loginRequired}>
                                {t('search:loginRequired')} <Icon icon={ExternalLinkIcon}/>
                              </span>
                            </>
                          )}
                        </p>
                      }/>
        </span>
      </button>
      <Collapse in={isOpen && (!requiresAuth || isAuthenticated)}>
        <CardContent aria-live='polite' className={styles.content}>{children}</CardContent>
      </Collapse>
    </Card>
  );
};
