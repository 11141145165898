import {ValidatorFn, ValidatorResult} from 'features/search/validators';

const weights1 = [3, 7, 6, 1, 8, 9, 4, 5, 2];
const weights2 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

const getChecksum = (val: string, weights: number[]) => {
  let sum = 0;
  for (let i = 0; i < weights.length; i++) {
    sum += parseInt(val[i], 10) * weights[i];
  }
  const remainder = sum % 11;
  return remainder ? 11 - remainder : 0;
};

export const fnrValidator: ValidatorFn = (val: string): ValidatorResult => {
  if (!val.match(/^\d{11}$/)) {
    return {valid: false, error: 'invalid'};
  } else if (
    getChecksum(val.substring(0,  9), weights1) !== parseInt(val[9], 10) ||
    getChecksum(val.substring(0, 10), weights2) !== parseInt(val[10], 10)
  ) {
    return {valid: false, error: 'mod11'};
  }
  return {valid: true};
};
