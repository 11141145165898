import {createUseStyles} from 'react-jss';
import {ClipboardEvent, FC, FormEventHandler, Suspense, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from 'common/components/button';
import {getEnv} from 'environment/public.environment';
import {Panel} from 'common/components/panel';
import {CssBreakpoints} from 'styles/responsive';
import {colorPalette} from 'styles/colors';
import {StringUtils} from 'common/utils/string-utils';
import dynamic from 'next/dynamic';
import type {Nullable} from 'common/types';
import {Loading} from 'common/components/loading';
import {RettsstiftelseIdentifierType} from 'features/rettsstiftelse/model/rettsstiftelse-identifier';
import {ExclamationmarkTriangleFillIcon, MagnifyingGlassIcon} from '@navikt/aksel-icons';
import {Icon} from 'common/components/icon';
import {ValidatorFn} from 'features/search/validators';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';

const DynInput = dynamic(() => import('common/components/input'), {
  ssr: false
});

const useRettsstiftelseSearchFormStyles = createUseStyles((theme) => ({
  form: {
    margin: 0,
  },
  label: {
    display: 'block',
    color: theme.colors.text.p,
    fontSize: '1.125rem',
    fontWeight: 600,

    [`@media (max-width: ${CssBreakpoints.SM}px)`]: {
      fontSize: '1rem'
    }
  },
  labelErrorIcon: {
    color: `${colorPalette.red60}`,
    marginLeft: '0.3em'
  },
  searchBoxContainer: {
    display: 'flex',
  },
  searchFormContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }

}), {name: 'RettsstiftelseSearchForm'});

interface RettsstiftelseSearchFormProps {
  searchType: RettsstiftelseIdentifierType;
  validator: ValidatorFn;
  onSearch: (val: string) => void;
  inputName?: string;
  disableAutocomplete?: boolean;
  mask?: string;
  register: 'losore' | 'ektepakt';
}

export const RettsstiftelseSearchForm: FC<RettsstiftelseSearchFormProps> = ({
  searchType,
  validator,
  onSearch,
  inputName,
  disableAutocomplete,
  mask,
  register
}) => {
  const styles = useRettsstiftelseSearchFormStyles();
  const {t} = useTranslation(['search']);

  const [inputFieldValue, setInputFieldValue] = useState<string>('');
  const [lastEnteredValue, setLastEnteredValue] = useState<string>('');
  const [formError, setFormError] = useState<Nullable<string>>(null);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  const queryParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (
      queryParams?.has('error') &&
      queryParams.get('register') === register &&
      queryParams.get('type') === searchType
    ) {
      setFormError(queryParams.get('error'));
      const erroredIdentifier = queryParams.get('query');
      if (erroredIdentifier) {
        setLastEnteredValue(erroredIdentifier);
      }
      router?.replace(pathname + window.location.hash);
    }
  }, [queryParams, router]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    if (isLoadingResults) {
      return;
    }

    const validationRes = validator(inputFieldValue);
    if (validationRes.valid) {
      setIsLoadingResults(true);
      setFormError(null);
      onSearch(inputFieldValue);
    } else {
      setLastEnteredValue(inputFieldValue);
      setFormError(`validation_${validationRes.error}`);
    }
  };

  const onBlur = () => {
    const validationRes = validator(inputFieldValue);
    if (!validationRes.valid && inputFieldValue !== '') {
      setFormError('validation_' + validationRes.error);
      setLastEnteredValue(inputFieldValue);
    } else if (formError?.startsWith('validation_')) {
      setFormError(null);
    }
  };

  const onCopy = (event: ClipboardEvent<HTMLInputElement> & {
    target: { selectionStart: number; selectionEnd: number };
  }) => {
    const selectedPart = StringUtils.formatInputValue(inputFieldValue, searchType, mask)
      ?.substring(event.target.selectionStart, event.target.selectionEnd)
      .replace(/\s/g, '') ?? '';

    event.clipboardData.setData('text/plain', selectedPart);
    event.preventDefault();
  };

  const onChange = (value: string) => {
    const newValue = value.replace(/(\s|\u200c|\-)/g, '');
    setInputFieldValue(newValue);
    if (formError?.startsWith('validation_')) {
      const validationRes = validator(newValue);
      switch (formError) {
        case 'validation_mod11':
          if (validationRes.valid || newValue === '') {
            setFormError(null);
            setLastEnteredValue(newValue);
          }
          break;
        default:
          if (validationRes.valid || newValue === '' || validationRes.error !== formError.split('_')[1]) {
            setFormError(null);
            setLastEnteredValue(newValue);
          }
      }
    }
  };

  useEffect(() => {
    if (formError?.startsWith('status_5')) {
      setFormError('loadingError');
    }
  }, [formError]);

  const isInputError = () => {
    return !!formError?.length && (formError.startsWith('validation') || formError === 'notFound');
  };

  const i18nGroup = register === 'losore' ? searchType : register;

  return (
    <>
      <div className={styles.searchBoxContainer}>
        <form onSubmit={handleSubmit} className={styles.form} aria-busy={isLoadingResults}>
          <label htmlFor={`${register}-${searchType}-input`} className={styles.label}>
            {t(`search:${i18nGroup}.form.input.label`)}
            {formError && <Icon className={styles.labelErrorIcon} icon={ExclamationmarkTriangleFillIcon}/>}
          </label>
          <div className={styles.searchFormContainer}>
            <Suspense>
              <DynInput id={`${register}-${searchType}-input`}
                        placeholder={t(`search:${i18nGroup}.form.input.placeholder`, {'separator': '\u200c'})}
                        defaultValue={validator(inputFieldValue).valid ? inputFieldValue : ''}
                        data-testid={`${register}-${searchType}-input`}
                        error={isInputError()}
                        onBlur={onBlur}
                        onCopy={(e: any) => onCopy(e)}
                        onChange={onChange}
                        spellCheck={false}
                        name={inputName}
                        autoComplete={disableAutocomplete ? 'off' : 'on'}
                        mask={mask}
                        unmask={true}/>
            </Suspense>
            <Button variant="primary"
                    data-transaction-name={`search-${register}-${searchType}`}
                    id={`${register}-${searchType}-submit`}
                    data-testid={`${register}-${searchType}-submit`}
                    type="submit"
                    icon={MagnifyingGlassIcon}
                    iconAfter
                    disabled={inputFieldValue === '' || isLoadingResults}>
              {t(`search:${i18nGroup}.form.search-button`)}
            </Button>
            {isLoadingResults && (
              <Loading/>
            )}
          </div>
        </form>


      </div>
      {formError && (
        <Panel id={`${register}-${searchType}-form-error-message`}
               variant="error"
               heading={formError === 'validationInvalidRedirect'
                 ? t(`search:${i18nGroup}.form.error.${formError}.heading`)
                 : t(`search:${i18nGroup}.form.error.${formError}.heading`,
                   {value: StringUtils.formatInputValue(lastEnteredValue, searchType, mask)})}>
          {
            formError === 'loadingError'
              ? (
                <>
                  {t(`search:${i18nGroup}.form.error.loadingError.description.prelink`)}
                  <a href={getEnv().links.brreg.driftsMeldinger}>
                    {t(`search:${i18nGroup}.form.error.loadingError.description.link`)}
                  </a>
                </>
              ) : t(`search:${i18nGroup}.form.error.${formError}.description`)
          }
        </Panel>
      )}

    </>
  );
};
