import {HttpError} from 'common/errors/http-error';
import {Nullable} from 'common/types';
import {getEnv} from 'environment/public.environment';

export const generateTempPersonKey = async (fnr: string, token?: string, metadata?: Record<string, any>): Promise<Response> => {
  return await fetch(`${getEnv().personTempKeyApi.url}/generate-key`, {
    body: JSON.stringify({fnr, metadata}),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    cache: 'no-cache'
  });
};

export const unsafeGenerateTempPersonKey = async (fnr: string, token: string, metadata?: Record<string, any>): Promise<{key: string}> => {
  const res = await generateTempPersonKey(fnr, token, metadata);

  if (res.status === 200) {
    return await res.json();
  }

  throw new HttpError(`Failed fetching key from "${getEnv().personTempKeyApi.url}"`, res.status, res.statusText);
};

interface FetchResult {
  fnr: string;
  metadata: Record<string, any>;
}

const unsafeGetFetchResult = async (baseUrl: string, key: string, headers: HeadersInit = {}): Promise<Nullable<FetchResult>> => {
  const res = await fetch(`${baseUrl}/fnr/${key}`, {headers, cache: 'no-cache'});

  if (res.status === 200) {
    return await res.json();
  } else if (res.status === 404) {
    return null;
  } else {
    throw new HttpError(`Failed fetching fnr from "${baseUrl}"`, res.status, res.statusText);
  }
};

export const unsafeFetchFnrFromPublicKey = async (publicKey: string): Promise<Nullable<FetchResult>> => {
  return unsafeGetFetchResult(getEnv().internalPersonTempKeyApi.url, publicKey);
};

export const unsafeFetchFnrFromKey = async (key: string, token: string): Promise<Nullable<FetchResult>> => {
  return unsafeGetFetchResult(getEnv().personTempKeyApi.url, key, {
    'Authorization': `Bearer ${token}`
  });
};
