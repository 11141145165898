export class HttpError extends Error {
  statusCode: number;
  statusDescription: string;

  constructor(message: string, statusCode: number, statusDescription: string) {
    super(`${message} [${statusCode} ${statusDescription}]`);
    this.statusCode = statusCode;
    this.statusDescription = statusDescription;
  }
};
